import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { groupBy, map, reduce } from 'lodash'
import { Box, Button, Flex, Text } from '@chakra-ui/react'

import { GetDayZoneIntervalsResponse } from 'shared/models'

import {
  tableDataSignal,
  isDataChangedSignal,
  tableUpdatedDataSignal,
} from '../signals/signals'

import { DayZoneIntervalsTableRow } from '../types/types'

// import { UeSystemOperatorHoursTable } from './UeSystemOperatorHoursTable/UeSystemOperatorHoursTable'

// misc
import { MdOutlineSave } from 'react-icons/md'
import { HiMiniXMark, HiOutlinePencilSquare } from 'react-icons/hi2'
import { DayZoneIntervalsTable } from './DayZoneIntervalsTable/DayZoneIntervalsTable'

interface EditDayZoneIntervalsProps {
  data: GetDayZoneIntervalsResponse[]
  downloadReportSlot: React.ReactNode
  year: string
  ue_system_id: string
}

const transformDataForTable = (data): DayZoneIntervalsTableRow[] => {
  const groupedByMonthAndDayZone = groupBy(
    data,
    (item) => `${item.month}_${item.day_zone}`,
  )

  const result = map(groupedByMonthAndDayZone, (items, key) => {
    const [month, dayZone] = key.split('_')

    return reduce(
      items,
      (result, item) => {
        const hourNumber = item.hour_number
        result[`${hourNumber}_is_peak`] = item.is_peak
        result[`${hourNumber}_dz_intervals_id`] = item.dz_intervals_id
        result[`${hourNumber}_day_zone`] = item.day_zone
        return result
      },
      { month, day_zone: dayZone },
    )
  })

  const sortedResult = result.sort((a, b) => {
    if (a.month < b.month) return -1
    if (a.month > b.month) return 1
    if (a.day_zone === 'ночь' && b.day_zone === 'пик') return -1
    if (a.day_zone === 'пик' && b.day_zone === 'ночь') return 1
    return 0
  })
  return sortedResult as DayZoneIntervalsTableRow[]
}

export const EditDayZoneIntervals: React.FC<EditDayZoneIntervalsProps> = ({
  data,
  downloadReportSlot,
  ue_system_id,
  year,
}) => {
  const [editMode, setEditMode] = useState<boolean>(false)

  const transformedData = useMemo(() => transformDataForTable(data), [data])

  console.log('transformedData===>', transformedData)

  const [tableData, setTableData] = useState(
    transformedData as DayZoneIntervalsTableRow[],
  )

  useEffect(() => {
    setTableData(transformedData)
    tableDataSignal.value = transformedData
  }, [data])

  const toggleEditMode = () => setEditMode(!editMode)

  const handleCancelChanges = useCallback(() => {
    setTableData(transformedData)
    setEditMode(false)
    isDataChangedSignal.value = false
  }, [data])

  const saveChanges = useCallback(() => {
    if (!isDataChangedSignal.value) {
      setEditMode(false)
      return
    }
    tableUpdatedDataSignal.value = []
  }, [tableData])

  const dayZoneIntervalsTableProps = useMemo(
    () => ({
      tableData,
      editMode,
      saveChanges,
      toggleEditMode,
    }),
    [tableData, editMode, saveChanges, toggleEditMode],
  )

  return (
    <Box position={'relative'}>
      <Box
        position={'absolute'}
        left={0}
        top={0}
        fontStyle="italic"
        fontSize={'14px'}
        fontWeight={'600'}
      >
        Время московское (МСК+0) <br />
        Нумерация часов в соответствии с документом об утверждении интервалов
        тарифных зон
      </Box>
      {data && (
        <Box p={'17px'} pt={'15px'}>
          <>
            <Flex
              mb={'10px'}
              ml={'auto'}
              mr={'-25px'}
              justifyContent={'flex-end'}
            >
              <Flex alignItems={'center'}>
                {editMode && (
                  <Button
                    mr={'5px'}
                    size={'xs'}
                    // isLoading={isLoading}
                    // isDisabled={isLoading}
                    bg="teal.400"
                    color="white"
                    display={'flex'}
                    alignItems={'center'}
                    onClick={saveChanges}
                    _hover={{
                      bg: 'teal.500',
                    }}
                  >
                    <Box fontSize={'22px'}>
                      <MdOutlineSave />
                    </Box>
                    Сохранить
                  </Button>
                )}

                {editMode ? (
                  <Button
                    mr={'5px'}
                    position={'relative'}
                    size={'xs'}
                    display={'flex'}
                    alignItems={'center'}
                    onClick={handleCancelChanges}
                    // isDisabled={isLoading}
                    // isLoading={isLoading}
                  >
                    <Box fontSize={'18px'}>
                      <HiMiniXMark />
                    </Box>
                    <Text position={'relative'}>Отмена</Text>
                  </Button>
                ) : (
                  <Button
                    mr="5px"
                    position={'relative'}
                    size={'xs'}
                    color="white"
                    display={'flex'}
                    alignItems={'center'}
                    onClick={() => setEditMode(true)}
                    colorScheme="blue"
                    // isDisabled={isLoading}
                    // isLoading={isLoading}
                  >
                    <Box fontSize={'22px'} mr={'5px'}>
                      <HiOutlinePencilSquare />
                    </Box>
                    <Text position={'relative'}>Редактировать</Text>
                  </Button>
                )}

                {downloadReportSlot && downloadReportSlot}
              </Flex>
            </Flex>
            <DayZoneIntervalsTable {...dayZoneIntervalsTableProps} />
          </>
        </Box>
      )}
    </Box>
  )
}
