/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'

import { BasicTable } from 'shared/ui'
import { TableStructure } from './TableStructure'
import { UeSystemOperatorHoursTableRow } from '../../types/types'
import { Box } from '@chakra-ui/react'

interface ITableEntityProps {
  tableData: UeSystemOperatorHoursTableRow[]
  editMode: boolean
}

export const TableEntity: React.FC<ITableEntityProps> = (
  props,
): JSX.Element => {
  const { tableData = [], editMode } = props

  const columns = TableStructure(editMode)

  const styles = {
    textAlign: 'center',
    fontSize: '14px',
  }

  const CustomHeader = () => (
    <>
      <tr>
        <th
          {...({
            colSpan: 25,
            style: { ...styles },
          } as any)}
        >
          <Box ml="40px">Интервалы</Box>
        </th>
      </tr>
      <tr>
        <th
          {...({
            colSpan: 1,
            style: { ...styles },
          } as any)}
        >
          С
        </th>
        {...Array.from({ length: 24 }).map((_, i) => (
          <th
            {...({
              colSpan: 1,
              style: { ...styles },
            } as any)}
          >
            {i}:00
          </th>
        ))}
      </tr>
      <tr>
        <th
          {...({
            colSpan: 1,
            style: { ...styles },
          } as any)}
        >
          По
        </th>
        {...Array.from({ length: 24 }).map((_, i) => (
          <th
            {...({
              colSpan: 1,
              style: { ...styles },
            } as any)}
          >
            {i + 1 === 24 ? 0 : i + 1}:00
          </th>
        ))}
      </tr>
    </>
  )

  const tableProps = {
    tableStyles: {
      tableMaxHeight: '65vh',
      tableWidth: '100%',
    },
    data: tableData,
    columns,
    emptyText: 'Данные отсутствуют',
    loading: false,
    showTableMenu: false,
    smallShadow: true,
    lightHeader: true,
    // smallHeader: true,
    customHeader: () => <CustomHeader />,
  }

  return (
    <>
      <BasicTable {...tableProps} />
    </>
  )
}
