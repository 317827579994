import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { groupBy, map, reduce } from 'lodash'
import { Box, Button, Flex, Text } from '@chakra-ui/react'

import {
  GetUeSystemOperatorHoursResponse,
  UpdateUeSystemOperatorHours,
} from 'shared/models'

import {
  tableDataSignal,
  isDataChangedSignal,
  tableUpdatedDataSignal,
} from '../signals/signals'

import { UeSystemOperatorHoursTable } from './UeSystemOperatorHoursTable/UeSystemOperatorHoursTable'
import { useUpdateUeSystemOperatorHours } from '../models/services/editUeSystemOperatorHoursServie'

// misc
import { MdOutlineSave } from 'react-icons/md'
import { HiMiniXMark, HiOutlinePencilSquare } from 'react-icons/hi2'
import { UeSystemOperatorHoursTableRow } from '../types/types'

interface EditUeSystemOperatorHoursProps {
  data: GetUeSystemOperatorHoursResponse[]
  downloadReportSlot: React.ReactNode
  year: string
  price_zone_id: string
}

const transformDataForTable = (data): UeSystemOperatorHoursTableRow[] => {
  const groupedByMonth = groupBy(data, 'month')
  return map(groupedByMonth, (items, month) => {
    return reduce(
      items,
      (result, item) => {
        const hourNumber = item.hour_number
        result[`${hourNumber}_is_peak`] = item.is_peak
        result[`${hourNumber}_ues_operator_hours_id`] =
          item.ues_operator_hours_id
        return result
      },
      { month },
    )
  })
}

export const EditUeSystemOperatorHours: React.FC<
  EditUeSystemOperatorHoursProps
> = ({ data, downloadReportSlot, price_zone_id, year }) => {
  const [editMode, setEditMode] = useState<boolean>(false)

  const { mutate: updateUeSystemOperatorHours, isLoading } =
    useUpdateUeSystemOperatorHours()

  const transformedData = useMemo(() => transformDataForTable(data), [data])

  const [tableData, setTableData] = useState(
    transformedData as UeSystemOperatorHoursTableRow[],
  )

  useEffect(() => {
    setTableData(transformedData)
    tableDataSignal.value = transformedData
  }, [data])

  const toggleEditMode = () => setEditMode(!editMode)

  const handleCancelChanges = useCallback(() => {
    setTableData(transformedData)
    setEditMode(false)
    isDataChangedSignal.value = false
  }, [data])

  const saveChanges = useCallback(() => {
    if (!isDataChangedSignal.value) {
      setEditMode(false)
      return
    }

    setTableData(tableDataSignal.value)

    updateUeSystemOperatorHours({
      data: tableUpdatedDataSignal.value as unknown as UpdateUeSystemOperatorHours[],
      price_zone_id,
      date_period: year,
      successAction: () => {
        isDataChangedSignal.value = false
        setEditMode(false)
        tableUpdatedDataSignal.value = []
      },
    })
  }, [tableData])

  const ueSystemOperatorHoursTableProps = useMemo(
    () => ({
      tableData,
      editMode,
      saveChanges,
      toggleEditMode,
    }),
    [tableData, editMode, saveChanges, toggleEditMode],
  )

  return (
    <Box position={'relative'}>
      <Box
        position={'absolute'}
        left={0}
        top={0}
        fontStyle="italic"
        fontSize={'14px'}
        fontWeight={'600'}
      >
        Время московское (МСК+0) <br />
        Нумерация часов в соответствии с документом об утверждении плановых
        часов пиковой нагрузки
      </Box>
      {data && (
        <Box p={'17px'} pt={'15px'}>
          <>
            <Flex
              mb={'10px'}
              ml={'auto'}
              mr={'-25px'}
              justifyContent={'flex-end'}
            >
              <Flex alignItems={'center'}>
                {editMode && (
                  <Button
                    mr={'5px'}
                    size={'xs'}
                    isLoading={isLoading}
                    isDisabled={isLoading}
                    bg="teal.400"
                    color="white"
                    display={'flex'}
                    alignItems={'center'}
                    onClick={saveChanges}
                    _hover={{
                      bg: 'teal.500',
                    }}
                  >
                    <Box fontSize={'22px'}>
                      <MdOutlineSave />
                    </Box>
                    Сохранить
                  </Button>
                )}

                {editMode ? (
                  <Button
                    mr={'5px'}
                    position={'relative'}
                    size={'xs'}
                    display={'flex'}
                    alignItems={'center'}
                    onClick={handleCancelChanges}
                    isDisabled={isLoading}
                    isLoading={isLoading}
                  >
                    <Box fontSize={'18px'}>
                      <HiMiniXMark />
                    </Box>
                    <Text position={'relative'}>Отмена</Text>
                  </Button>
                ) : (
                  <Button
                    mr="5px"
                    position={'relative'}
                    size={'xs'}
                    color="white"
                    display={'flex'}
                    alignItems={'center'}
                    onClick={() => setEditMode(true)}
                    colorScheme="blue"
                    isDisabled={isLoading}
                    isLoading={isLoading}
                  >
                    <Box fontSize={'22px'} mr={'5px'}>
                      <HiOutlinePencilSquare />
                    </Box>
                    <Text position={'relative'}>Редактировать</Text>
                  </Button>
                )}

                {downloadReportSlot && downloadReportSlot}
              </Flex>
            </Flex>
            <UeSystemOperatorHoursTable {...ueSystemOperatorHoursTableProps} />
          </>
        </Box>
      )}
    </Box>
  )
}
