import { signal } from '@preact/signals-react'
import { UeSystemOperatorHoursTableRow } from '../types/types'

export const tableDataSignal = signal<UeSystemOperatorHoursTableRow[]>()
export const tableUpdatedDataSignal = signal<
  {
    ues_operator_hours_id: string
    is_peak: boolean
  }[]
>([])
export const isDataChangedSignal = signal(false)
