import React from 'react'
import { Box, Flex } from '@chakra-ui/react'

import { GetViewFSKTariffsData, UploadFileInfo } from 'shared/models'
import { EmptyDataMessage, UploadFileInfoView } from 'shared/ui'
import { FSKTariffsTable } from './FSKTariffsTable/FSKTariffsTable'

interface DataViewFSKTariffsTableProps {
  data: GetViewFSKTariffsData[]
  upload_info: UploadFileInfo
  downloadReportSlot?: JSX.Element
}

export const DataViewFSKTariffsTable: React.FC<
  DataViewFSKTariffsTableProps
> = ({ data, upload_info, downloadReportSlot }) => {
  return (
    <Box maxW={'800px'}>
      <Flex mb="17px">
        {data && data?.length !== 0 && (
          <Flex ml="auto" mr={'-10px'} alignItems={'center'}>
            {upload_info && <UploadFileInfoView {...upload_info} />}
            {downloadReportSlot ? downloadReportSlot : null}
          </Flex>
        )}
      </Flex>

      {data && (
        <Box p={'17px'} pt={'0px'}>
          {Array.isArray(data) && data.length === 0 ? (
            <EmptyDataMessage />
          ) : (
            <>
              <FSKTariffsTable tableData={data} />
            </>
          )}
        </Box>
      )}
    </Box>
  )
}
