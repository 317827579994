import { AxiosError, AxiosResponse } from 'axios'
import { UseMutateFunction, useMutation, useQueryClient } from 'react-query'

import { queryKeys } from 'shared/constants'
import { ExtendedAxiosError, UpdateUeSystemOperatorHours } from 'shared/models'
import { PricesServiceService } from 'shared/services'
import { toasts } from 'shared/utils'

interface UseUpdateUeSystemOperatorHoursMutation {
  successAction: () => void
  data: UpdateUeSystemOperatorHours[]
  price_zone_id: string
  date_period: string
}

export function useUpdateUeSystemOperatorHours(): {
  mutate: UseMutateFunction<
    AxiosResponse<undefined>,
    AxiosError,
    UseUpdateUeSystemOperatorHoursMutation,
    unknown
  >
  isLoading: boolean
} {
  const queryClient = useQueryClient()

  const { mutate, isLoading } = useMutation(
    ({
      data,
      price_zone_id,
      date_period,
    }: UseUpdateUeSystemOperatorHoursMutation) =>
      PricesServiceService.updateUeSystemOperatorHours({
        data,
      }),
    {
      onSuccess: (
        data: AxiosResponse<undefined>,
        variables: UseUpdateUeSystemOperatorHoursMutation,
      ) => {
        if (variables.successAction) {
          variables.successAction()
          toasts.success({
            title: 'Успех',
            description: `Таблица была успешно обновлена`,
          })
        }

        queryClient.invalidateQueries(
          [
            queryKeys.prices.ueSystemOperatorHours,
            variables.price_zone_id,
            variables.date_period,
          ],
          { refetchInactive: true },
        )
      },
      onError: (error: ExtendedAxiosError) =>
        toasts.error({
          title: 'Произошла ошибка',
          description:
            error.response.data.detail || 'Таблица не была обновлена',
        }),
    },
  )
  return { mutate, isLoading }
}
