import {
  Box,
  Button,
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/react'

import { HoursSOEAS, DayZoneIntervals } from 'widgets'

export const HoursAndIntervalsPage = () => {
  return (
    <Tabs>
      <TabList p={'10px'} pb={0}>
        <Tab>
          <Text fontSize={'18px'}>Часы СО ЕЭС</Text>
        </Tab>
        <Tab>
          <Text fontSize={'18px'}>Интервалы зон суток</Text>
        </Tab>
      </TabList>

      <TabPanels>
        <TabPanel padding={0}>
          <HoursSOEAS />
        </TabPanel>
        <TabPanel padding={0}>
          <DayZoneIntervals />
        </TabPanel>
      </TabPanels>
    </Tabs>
  )
}
