import { CellProps } from 'react-table'

import {
  BasicCommonColumn,
  BasicDateColumn,
  BasicTitle,
  HoursPeakColumn,
} from 'shared/ui'

import {
  tableUpdatedDataSignal,
  tableDataSignal,
  isDataChangedSignal,
} from '../../signals/signals'
import { Box, Flex } from '@chakra-ui/react'

const size = 'md'

const getRowSpanForMonth = (row, rows) => {
  const currentMonth = row.original.month
  let rowSpan = 1

  for (let i = row.index + 1; i < rows.length; i++) {
    if (rows[i].original.month === currentMonth) rowSpan++
    else break
  }

  if (row.index > 0 && rows[row.index - 1].original.month === currentMonth)
    return 0

  return rowSpan
}

export const TableStructure = (editMode) => {
  const handleChange = ({
    id,
    newValue,
    index,
  }: {
    id: string
    newValue: boolean
    index: string
    row?: any
  }) => {
    const newData = tableDataSignal.peek().map((item) => {
      if (item[`${Number(index) + 1}_ues_operator_hours_id`] === id) {
        return {
          ...item,
          [`${Number(index) + 1}_is_peak`]: newValue,
          [`${Number(index) + 1}_edited`]: true,
        }
      }
      return item
    })

    tableDataSignal.value = newData

    const existingEntryIndex = tableUpdatedDataSignal.value.findIndex(
      (entry) => entry.ues_operator_hours_id === id,
    )

    if (existingEntryIndex > -1) {
      tableUpdatedDataSignal.value[existingEntryIndex].is_peak = newValue
    } else {
      tableUpdatedDataSignal.value = [
        ...tableUpdatedDataSignal.value,
        {
          ues_operator_hours_id: id,
          is_peak: newValue,
        },
      ]
    }

    if (!isDataChangedSignal.value) isDataChangedSignal.value = true
  }

  const structure = [
    {
      title: () => <BasicTitle size={size}>Месяц</BasicTitle>,
      accessor: 'month',
      disableSortBy: true,
      minWidth: 140,
      width: 140,
      className: 'hours-cell',
      CellComponent: ({ cell, row, column, rows }: CellProps<any, string>) => {
        const rowSpan = getRowSpanForMonth(row, rows)
        if (rowSpan === 0) return null

        const groupIndex = Math.floor(row.index / 2)
        const bgColor = groupIndex % 2 === 0 ? '#FAFBFF' : 'white'

        return (
          <Flex
            w={'100%'}
            h={'100%'}
            display={'flex'}
            alignItems={'center'}
            // borderBottom={'1px solid red'}
            position={'relative'}
          >
            <Flex
              w={'100%'}
              h={'44px'}
              position={'absolute'}
              // border={'1px solid blue'}
              top={'-10px'}
              bg={bgColor}
              alignItems={'center'}
              justifyContent={'center'}
            >
              <BasicDateColumn
                dateFormat="date_month_string_with_year"
                align="left"
                size={'lg'}
              >
                {row.original.month}
              </BasicDateColumn>
            </Flex>
          </Flex>
        )
      },
    },
    {
      title: () => <BasicTitle size={size}>Зона суток</BasicTitle>,
      accessor: 'day_zone',
      disableSortBy: true,
      minWidth: 50,
      width: 50,
      className: 'hours-cell',
      CellComponent: ({ cell, row, column }: CellProps<any, string>) => {
        return (
          <BasicCommonColumn size={size}>
            {row.original.day_zone}
          </BasicCommonColumn>
        )
      },
    },
    ...Array.from({ length: 24 }, (_, i) => ({
      title: () => (
        <BasicTitle size={size}>{i === 11 ? 'Часы' : ''}</BasicTitle>
      ),
      accessor: `${i}_is_peak`,
      padding: 0,
      disableSortBy: true,
      className: 'hours-cell',
      CellComponent: ({ row }: CellProps<any, string>) => {
        return (
          <HoursPeakColumn
            emptyValue
            onUpdate={handleChange}
            editMode={editMode}
            size={size}
            selected={row.original[`${i}_is_peak`]}
            row={row.original}
            index={String(i)}
          >
            <Box opacity={0}>{i + 1}</Box>
          </HoursPeakColumn>
        )
      },
    })),
  ]
  return structure
}
