import { CellProps } from 'react-table'

import { BasicSumColumn, BasicTitle } from 'shared/ui'

export const TableStructure = () => {
  const structure = [
    {
      title: () => <BasicTitle>Субъект РФ</BasicTitle>,
      accessor: 'region_name',
      disableSortBy: true,
      minWidth: 80,
      width: 80,
      CellComponent: ({ row }: CellProps<any, string>) => {
        return <BasicSumColumn>{row.original.region_name}</BasicSumColumn>
      },
    },
    {
      title: () => <BasicTitle>Тариф ФСК (потери), руб/МВт*ч</BasicTitle>,
      accessor: 'tariff_fgc	',
      disableSortBy: true,
      minWidth: 80,
      width: 80,
      CellComponent: ({ row }: CellProps<any, string>) => {
        return <BasicSumColumn>{row.original.tariff_fgc}</BasicSumColumn>
      },
    },
  ]

  return structure
}
