import { CellProps } from 'react-table'

import { BasicDateColumn, BasicTitle, HoursPeakColumn } from 'shared/ui'

import {
  tableUpdatedDataSignal,
  tableDataSignal,
  isDataChangedSignal,
} from '../../signals/signals'

const size = 'lg'

export const TableStructure = (editMode) => {
  const handleChange = ({
    id,
    newValue,
    index,
  }: {
    id: string
    newValue: boolean
    index: string
    row?: any
  }) => {
    const newData = tableDataSignal.peek().map((item) => {
      if (item[`${Number(index) + 1}_ues_operator_hours_id`] === id) {
        return {
          ...item,
          [`${Number(index) + 1}_is_peak`]: newValue,
          [`${Number(index) + 1}_edited`]: true,
        }
      }
      return item
    })

    tableDataSignal.value = newData

    const existingEntryIndex = tableUpdatedDataSignal.value.findIndex(
      (entry) => entry.ues_operator_hours_id === id,
    )

    if (existingEntryIndex > -1)
      tableUpdatedDataSignal.value[existingEntryIndex].is_peak = newValue
    else
      tableUpdatedDataSignal.value = [
        ...tableUpdatedDataSignal.value,
        {
          ues_operator_hours_id: id,
          is_peak: newValue,
        },
      ]

    if (!isDataChangedSignal.value) isDataChangedSignal.value = true
  }

  const structure = [
    {
      title: () => <BasicTitle size={size}>Месяц</BasicTitle>,
      accessor: 'month	',
      disableSortBy: true,
      minWidth: 180,
      width: 180,
      className: 'hours-cell',
      CellComponent: ({ cell, row, column }: CellProps<any, string>) => {
        return (
          <BasicDateColumn
            dateFormat="date_month_string_with_year"
            align="left"
            size={size}
          >
            {row.original.month}
          </BasicDateColumn>
        )
      },
    },
    ...Array.from({ length: 24 }, (_, i) => ({
      title: () => (
        <BasicTitle size={size}>{i === 11 ? 'Часы' : ''}</BasicTitle>
      ),
      accessor: `${i}_is_peak`,
      padding: 0,
      disableSortBy: true,
      className: 'hours-cell',
      CellComponent: ({ row }: CellProps<any, string>) => {
        return (
          <HoursPeakColumn
            onUpdate={handleChange}
            editMode={editMode}
            // size="xs"
            selected={row.original[`${i + 1}_is_peak`]}
            row={row.original}
            index={String(i)}
          >
            {i + 1}
          </HoursPeakColumn>
        )
      },
    })),
  ]
  return structure
}
